import Head from "next/head";
import React, { useCallback, useEffect, useState } from "react";
import MerakiSDK, {
  ANALYTICS_EVENTS,
} from "@urbanpiper-engineering/meraki-sdk";
import { Exceptions } from "@urbanpiper-engineering/meraki-sdk";
import Image from "next/image";

import { getStaticProps as internalProps } from "../../hooks/server.props";

import Carousel from "@urbanpiper-engineering/meraki-components/dist/Cells/Carousel";
import MenuPanel from "@urbanpiper-engineering/meraki-components/dist/Organisms/MenuPanel";

import styles from "./menu.module.scss";
import { CartState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/cart/states/cart.state";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { StoreState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/stores/states/store.state";
import configSelector from "../../external/configSelector";
import { PubSub, EVENTS } from "../../utils/listeners";
import pageSelector from "../../external/pageSelector";
import { useTranslation } from "react-i18next";
import { onItemUpdate } from "../../hooks/cart.util";
import { onItemCustomization } from "../../hooks/cart.util";
import { useIsMobile } from "../../hooks/is_mobile.hook";

const FloatingStack = dynamic(
  () =>
    import(
      "@urbanpiper-engineering/meraki-components/dist/Atoms/FloatingStack"
    ),
  { ssr: false }
);

const FloatingCart = dynamic(
  () =>
    import("@urbanpiper-engineering/meraki-components/dist/Cells/FloatingCart"),
  { ssr: false }
);

const FloatingStatus = dynamic(
  () =>
    import(
      "@urbanpiper-engineering/meraki-components/dist/Molecules/FloatingStatus"
    ),
  { ssr: false }
);

export async function getStaticProps(props: any) {
  const intialProps = await internalProps(undefined, props.locale);

  const seoConfig =
    configSelector.getPageSEO({
      name: "menu",
      locale: props.locale,
    }) || {};

  const {
    likeButton,
    recommendedItems,
    showFoodType,
    showItemSort,
    showItemFilter,
  } = pageSelector.getMenuPage().customizations;

  const banners = await MerakiSDK.getBannerImages({
    type: ["web_banner", "mobile_web_banner"],
  });

  const { webBanner, mobileBanner } = banners?.images?.reduce(
    (acc: any, banner: any) => {
      if (banner.img_type.match("web_banner") !== null) {
        acc.webBanner.push(banner);
      }
      if (banner.img_type.match("mobile_web_banner") !== null) {
        acc.mobileBanner.push(banner);
      }
      return acc;
    },
    { webBanner: [], mobileBanner: [] }
  );

  return {
    props: {
      ...intialProps.props,
      seoConfig,
      _webBanner: webBanner,
      _mobileBanner: mobileBanner,
      likeButton,
      recommendedItems,
      showFoodType,
      showItemSort,
      showItemFilter,
    },
  };
}

function MenuPage({
  _webBanner,
  _mobileBanner,
  categoryId,
  seoConfig,
  businessInfo,
  likeButton,
  recommendedItems,
  showFoodType,
  showItemSort,
  showItemFilter,
}: any) {
  const isMobile = useIsMobile();
  const [webBanner, setWebBanner] = useState<any>(_webBanner);
  const [mobileBanner, setMobileBanner] = useState<any>(_mobileBanner);
  const router = useRouter();
  const { t } = useTranslation();
  const useCartState = MerakiSDK.getCartState().hook();
  const useStoreState = MerakiSDK.getStoreState().hook();
  const useUserState = MerakiSDK.getUserState().hook();

  const [cartItems, totalItems, subTotal] = useCartState(
    (state: typeof CartState) => [
      state.cart?.items || [],
      state.cart?.item_count,
      state.cart?.sub_total,
    ]
  );

  const selectedStore = useStoreState(
    (state: typeof StoreState) => state.selectedStore
  );

  const [itemsMap, setItemsMap] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const language = useUserState((state: any) => state.language);
  const isUserAuthenticated = MerakiSDK.isUserAuthenticated();
  const [sortFilter, setSortFilter] = useState<any>({});
  const [activeOrder, setActiveOrder] = useState<any>(null);
  const [selectedfilterOptions, setSelectedfilterOptions] = useState<any>([]);
  const [selectedSortOptions, setSelectedSortOptions] = useState<any>(null);
  const [filterSortIndicatorData, setFilterSortIndicatorData] = useState<{
    showFilterIndicator: boolean;
    showSortIndicator: boolean;
  }>({ showFilterIndicator: false, showSortIndicator: false });
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);

  const onFilterByChange = (checked: any, id: any) => {
    if (checked && !selectedfilterOptions.includes(id)) {
      setSelectedfilterOptions([...selectedfilterOptions, id]);
    } else {
      if (selectedfilterOptions.includes(id)) {
        setSelectedfilterOptions(
          selectedfilterOptions.filter((filterId: any) => filterId != id)
        );
      }
    }
  };

  const onSortByChange = (id: any) => {
    setSelectedSortOptions(sortFilter.sort_by?.[id]);
  };

  const onFilterByCallback = (
    selectedSortOptions: any,
    selectedfilterOptions: any
  ) => {
    setSelectedSortOptions(selectedSortOptions);
    setSelectedfilterOptions(selectedfilterOptions);
    repopulateCategories(selectedSortOptions, selectedfilterOptions);

    try {
      if (selectedfilterOptions?.length) {
        const _selectedfilterOptions = [...selectedfilterOptions].map(Number);
        let appliedFilters: string[] = [];
        let filters = sortFilter?.filters || [];
        filters.map((filter: any) => {
          filter?.options?.forEach(
            ({ id, title }: { id: number; title: string }) => {
              if (_selectedfilterOptions.includes(id)) {
                appliedFilters.push(title);
              }
            }
          );
        });

        MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.FILTER_APPLIED, {
          filter_by: appliedFilters.join(", "),
        });
      }
    } catch (error) {
      console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.FILTER_APPLIED, error);
    }

    try {
      if (selectedSortOptions) {
        MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.SORT_APPLIED, {
          sort_by: selectedSortOptions,
        });
      }
    } catch (error) {
      console.log("AnalyticsPublish: ", ANALYTICS_EVENTS.SORT_APPLIED, error);
    }
  };

  const onFilterClearCallback = useCallback(() => {
    setSelectedfilterOptions([]);
    onFilterByCallback(selectedSortOptions, []);
    setFilterSortIndicatorData({
      showFilterIndicator: false,
      showSortIndicator: selectedSortOptions?.length > 0,
    });
  }, [selectedSortOptions]);

  const onSortClearCallback = useCallback(() => {
    setSelectedSortOptions(null);
    onFilterByCallback(null, selectedfilterOptions);
    setFilterSortIndicatorData({
      showFilterIndicator: selectedfilterOptions?.length > 0,
      showSortIndicator: false,
    });
  }, [selectedfilterOptions]);

  useEffect(() => {
    MerakiSDK.getSortFilters().then((_sortFilter: any) => {
      const _updatedSortFilter: any = {};
      if (showItemSort) {
        _updatedSortFilter["sort_by"] = _sortFilter["sort_by"];
      }
      if (showItemFilter) {
        _updatedSortFilter["filters"] = _sortFilter["filters"];
      }
      setSortFilter(_updatedSortFilter);
    });

    if (isUserAuthenticated) {
      MerakiSDK.getActiveOrders()
        .then((orders: any) => {
          if (orders.length > 0) {
            setActiveOrder(orders[0]);
          }
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [showItemSort, showItemFilter, isUserAuthenticated]);

  const updateBanners = async () => {
    const banners = await MerakiSDK.getBannerImages({
      type: ["web_banner", "mobile_web_banner"],
    });
    const { webBanner, mobileBanner } = banners?.images?.reduce(
      (acc: any, banner: any) => {
        if (banner.img_type.match("web_banner") !== null) {
          acc.webBanner.push(banner);
        }
        if (banner.img_type.match("mobile_web_banner") !== null) {
          acc.mobileBanner.push(banner);
        }
        return acc;
      },
      { webBanner: [], mobileBanner: [] }
    );
    return { webBanner, mobileBanner };
  };

  useEffect(() => {
    if (selectedStore?.id) {
      updateBanners()
        .then(({ webBanner, mobileBanner }) => {
          setWebBanner(webBanner);
          setMobileBanner(mobileBanner);
        })
        .catch(() => {
          setWebBanner([]);
          setMobileBanner([]);
        });
    }
  }, [selectedStore?.id]);

  const repopulateCategories = useCallback(
    (selectedSortOptions: any, selectedfilterOptions: any) => {
      setCategoriesLoading(true);
      MerakiSDK.getCategories({
        recommendedItems,
        sort_by: selectedSortOptions,
        filter_by: selectedfilterOptions,
      })
        .then((_categories: any) => {
          setAllCategories(_categories);
          if (!_categories.length) {
            setItemsMap({});
          }
          setFilterSortIndicatorData({
            showFilterIndicator: selectedfilterOptions?.length > 0,
            showSortIndicator: selectedSortOptions?.length > 0,
          });
        })
        .finally(() => {
          setCategoriesLoading(false);
        });
    },
    [recommendedItems, language]
  );

  useEffect(() => {
    setSelectedSortOptions(null);
    setSelectedfilterOptions([]);
    repopulateCategories(null, []);
  }, [selectedStore, recommendedItems, language]);

  const onCategoryChange = async (category: any) => {
    const categorySlug = category?.slug;

    if (categorySlug) {
      router.push(`/category/${category.id}/${categorySlug}`, "", {
        scroll: false,
      });
    }

    try {
      MerakiSDK.analyticsPublish(ANALYTICS_EVENTS.CATEGORY_DETAIL, {
        ...category,
      });
    } catch (error) {
      console.log(
        "AnalyticsPublish: ",
        ANALYTICS_EVENTS.CATEGORY_DETAIL,
        error
      );
    }
  };

  const toggleFavourite = async (item: any, status: boolean) => {
    if (status) {
      await MerakiSDK.addItemAsFavourite(item.id);
    } else {
      await MerakiSDK.removeItemAsFavourite(item.id);
    }
  };

  const onError = (err: Error, item: any) => {
    if (err.message === Exceptions.LOGIN_REQUIRED) {
      PubSub.getInstance().emit(EVENTS.LOGIN_REQUIRED);
    }
    if (err.message === Exceptions.STORE_SELECTION_REQUIRED) {
      PubSub.getInstance().emit(EVENTS.STORE_SELECTION_REQUIRED);
    }
  };

  const fetchCategoryItems = async (categoryIds: number[]) => {
    /**
     * TODO: We will need a config in root config which will decide whether to fetch all items
     * at once or once by once
     */
    // if (!categoryIds?.length) return;
    // // get unfetched categories
    // const unfetchedCategories = categoryIds.filter(
    //   (id: number) => !itemsMap[id] && !isNaN(id)
    // );
    // if (!unfetchedCategories?.length) return;
    // setItemsMap((itemsMap: any) => ({
    //   ...itemsMap,
    //   ...unfetchedCategories.reduce(
    //     (result, category) => ({
    //       ...result,
    //       [category]: [],
    //     }),
    //     {}
    //   ),
    // }));
    // const categoryItems = await MerakiSDK.getCategoryItems({
    //   categoryIds: unfetchedCategories,
    //   sort_by: selectedSortOptions,
    //   filter_by: selectedfilterOptions,
    // });
    // const categoryItemMap = categoryItems?.reduce((acc: any, category: any) => {
    //   acc[category.id] = category.items;
    //   return acc;
    // }, {});
    // setItemsMap((itemsMap: any) => ({
    //   ...itemsMap,
    //   ...categoryItemMap,
    // }));
  };

  const getFullCatalogue = async () => {
    const itemsMapTemp: any = {};
    allCategories.forEach((category: any) => {
      if (category?.sub_categories?.length) {
        category.sub_categories.forEach((subCategory: any) => {
          itemsMapTemp[subCategory.id] = [];
        });
      } else {
        itemsMapTemp[category.id] = [];
      }
    });

    const categoryIds = Object.keys(itemsMapTemp).map((id) => Number(id));

    const categoryItems = await MerakiSDK.getCategoryItems({
      categoryIds: categoryIds,
      sort_by: selectedSortOptions,
      filter_by: selectedfilterOptions,
    });

    const categoryItemMap = categoryItems?.reduce((acc: any, category: any) => {
      acc[category.id] = category.items;
      return acc;
    }, {});

    setItemsMap(categoryItemMap);
  };

  useEffect(() => {
    getFullCatalogue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories]);

  return (
    <div className={styles.page}>
      <div className={`${styles.menu} container`}>
        <Head>
          <title>{seoConfig?.title}</title>
          <meta name="description" content={seoConfig?.description} />
          <meta name="keywords" content={seoConfig?.keywords} />
        </Head>

        <Carousel
          classes={styles.carousel}
          mobileSlides={mobileBanner}
          desktopSlides={webBanner}
          imageComponent={Image}
        />

        <div className={styles.itemList}>
          <MenuPanel
            loading={categoriesLoading}
            isFavouriteEnabled={likeButton}
            showFoodType={showFoodType}
            minOrder={businessInfo?.min_order_total || 0}
            selectedCategory={Number(categoryId)}
            sortFilter={sortFilter}
            categories={allCategories}
            itemsMap={itemsMap}
            onCategoryClick={onCategoryChange}
            imageComponent={Image}
            fetchCategoryItems={fetchCategoryItems}
            onItemUpdate={onItemUpdate}
            cartItems={cartItems}
            totalItems={totalItems}
            subTotal={subTotal}
            onError={onError}
            onItemClicked={(item: any) => {
              sessionStorage.setItem(
                "scrolledSoFar",
                String(window.pageYOffset)
              );
              router.push(`/item/${item.id}/${item.slug}`);
            }}
            onCheckoutClicked={async () => {
              await router.push("/checkout");
            }}
            toggleFavourite={toggleFavourite}
            onFilterByCallback={onFilterByCallback}
            onFilterByChange={onFilterByChange}
            onSortByChange={onSortByChange}
            onFilterClearCallback={onFilterClearCallback}
            onSortClearCallback={onSortClearCallback}
            selectedfilterOptions={selectedfilterOptions}
            selectedSortOptions={selectedSortOptions}
            editCustomizationCallback={onItemCustomization}
            filterSortIndicatorData={filterSortIndicatorData}
            itemLevelInstructionsEnabled={true}
          />
        </div>

        {isMobile && totalItems > 0 && (
          <FloatingStack order={2}>
            <FloatingCart
              item_count={totalItems}
              minOrder={businessInfo?.min_order_total || 0}
              sub_total={subTotal}
              onClick={() => router.push("/checkout")}
            />
          </FloatingStack>
        )}

        {activeOrder && !totalItems ? (
          <FloatingStack order={1}>
            <FloatingStatus
              variant="order"
              onCtaClick={() => router.push(`/orders/${activeOrder.id}`)}
            />
          </FloatingStack>
        ) : null}
      </div>
    </div>
  );
}

export default MenuPage;
