/**
 * this file creates a pub/sub system for the app
 */

export class PubSub {
  private static instance: PubSub;
  private events: { [key: string]: Function[] } = {};

  private constructor() {}

  static getInstance() {
    if (!PubSub.instance) {
      PubSub.instance = new PubSub();
    }
    return PubSub.instance;
  }

  addListener(event: EVENTS, callback: Function) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
    return {
      event,
      index: this.events[event].length - 1,
    };
  }

  removeListener(listener: any) {
    if (!this.events?.[listener.event]?.[listener.index]) {
      return;
    }
    this.events[listener.event] = this.events[listener.event].filter(
      (cb, index) => index !== listener.index
    );
  }

  emit(event: EVENTS, ...args: any[]) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((cb) => cb(...args));
  }
}

export default PubSub.getInstance();

export enum EVENTS {
  LOGIN_REQUIRED,
  STORE_SELECTION_REQUIRED,
  ITEM_CUSTOMIZATION_REQUIRED,
  ITEM_REPEAT_OR_NOT_REQUIRED,
  ADD_TO_CART,
}
