import MerakiSDK, { Exceptions } from "@urbanpiper-engineering/meraki-sdk";
import { EVENTS, PubSub } from "../utils/listeners";

export const onItemUpdate = async (item: any, direction: number) => {
  try {
    await MerakiSDK.updateCartItem(item, { direction });
  } catch (err: any) {
    if (err.message === Exceptions.ITEM_CUSTOMIZATION_REQUIRED) {
      PubSub.getInstance().emit(EVENTS.ITEM_CUSTOMIZATION_REQUIRED, item);
    } else if (err.message === Exceptions.ITEM_REPEAT_OR_NOT_REQUIRED) {
      PubSub.getInstance().emit(EVENTS.ITEM_REPEAT_OR_NOT_REQUIRED, item);
    } else if (err.message === Exceptions.STORE_SELECTION_REQUIRED) {
      PubSub.getInstance().emit(EVENTS.STORE_SELECTION_REQUIRED);
    }
    throw err;
  }
};

export const onItemUpdateBySignature = async (item: any, direction: number) => {
  await MerakiSDK.updateCartItemBySignature(item, direction);
};

export const onItemCustomization = async (item: any) => {
  PubSub.getInstance().emit(EVENTS.ITEM_CUSTOMIZATION_REQUIRED, item, true);
};
